<template>
  <div>
    <h2 class="mb-4">Titration Lab: Results I (SIM Part 4)</h2>

    <p class="mb-2">
      a) Fill in the data table below using the data you collected during the experiment and any
      associated calculations.
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table dense>
        <thead>
          <tr>
            <td></td>
            <td style="text-align: center">Trial 1</td>
            <td style="text-align: center">Trial 2</td>
            <td style="text-align: center">Trial 3</td>
          </tr>

          <tr v-for="result in labResults1" :key="result.value">
            <td>
              <stemble-latex :content="result.property" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value1]" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value2]" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value3]" />
            </td>
          </tr>

          <tr>
            <td>Molarity of HCl solution (M)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input placeholder="0.150" :disabled="true" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input placeholder="0.150" :disabled="true" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input placeholder="0.150" :disabled="true" />
            </td>
          </tr>

          <tr v-for="result in labResults2" :key="result.value">
            <td>
              <stemble-latex :content="result.property" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value1]" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value2]" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value3]" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </p>

    <p class="mb-2 mt-4">
      b) Fill in the table below to summarize the results of the experiment. For the actual
      molarity, recall that the NaOH solution was prepared by diluting 4.50 mL of 6.00 M NaOH (with
      water) to 254.5 mL. These values can be considered to be exact for this question.
    </p>

    <v-simple-table dense style="max-width: 450px">
      <thead>
        <tr>
          <td>Average Molarity of NaOH (M)</td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input v-model="inputs.concNaOHAvg" />
          </td>
        </tr>
        <tr>
          <td>Actual Molarity of NaOH (M)</td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input v-model="inputs.trueConcNaOH" />
          </td>
        </tr>
        <tr>
          <td>Percent Error (%)</td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input v-model="inputs.pctError" />
          </td>
        </tr>
      </thead>
    </v-simple-table>

    <p class="mb-2 mt-6">
      c) Please upload your raw data and titration curve as a single file using the upload field
      below.
    </p>

    <p>
      <v-file-input v-model="attachments" multiple />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ubcTitrationReportSheet1',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        volInit1: null,
        volFinal1: null,
        volNaOH1: null,
        volHCl1: null,
        molHCl1: null,
        molNaOH1: null,
        concNaOH1: null,

        volInit2: null,
        volFinal2: null,
        volNaOH2: null,
        volHCl2: null,
        molHCl2: null,
        molNaOH2: null,
        concNaOH2: null,

        volInit3: null,
        volFinal3: null,
        volNaOH3: null,
        volHCl3: null,
        molHCl3: null,
        molNaOH3: null,
        concNaOH3: null,

        concNaOHAvg: null,
        trueConcNaOH: null,
        pctError: null,
      },
      attachments: [],
      labResults1: [
        {
          property: 'Initial burette volume (mL NaOH)',
          value1: 'volInit1',
          value2: 'volInit2',
          value3: 'volInit3',
        },
        {
          property: 'Final burette volume (mL NaOH)',
          value1: 'volFinal1',
          value2: 'volFinal2',
          value3: 'volFinal3',
        },
        {
          property: 'Volume of NaOH used (mL)',
          value1: 'volNaOH1',
          value2: 'volNaOH2',
          value3: 'volNaOH3',
        },
      ],
      labResults2: [
        {
          property: 'Volume of HCl solution used (mL)',
          value1: 'volHCl1',
          value2: 'volHCl2',
          value3: 'volHCl3',
        },
        {
          property: 'Moles of HCl (mol)',
          value1: 'molHCl1',
          value2: 'molHCl2',
          value3: 'molHCl3',
        },
        {
          property: 'Moles of NaOH (mol)',
          value1: 'molNaOH1',
          value2: 'molNaOH2',
          value3: 'molNaOH3',
        },
        {
          property: 'Molarity of NaOH (M)',
          value1: 'concNaOH1',
          value2: 'concNaOH2',
          value3: 'concNaOH3',
        },
      ],
    };
  },
};
</script>

<style></style>
